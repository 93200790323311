:root {
  --heroButton: #8093f1;
  --heroButtonColor: #f4f4ed;
  --position:center top;
  --bg-hero:url('https://vimeo.com/866544182');
}
.heroVideo {
  position: ;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  z-index:-100;
  opacity:;
  margin-top: -15vh;
}
.heroTop {
  background-color: #333;
  margin-top: -20vh;
  min-height:120vh;
}
.heroVideoSection {
  z-index: 1;
  position:absolute;
  background-color:#00000090;
  top:0;
  left:0;
  width:100%;
}
#divider {
  background:url('./divider.svg');
  height:200px;
}
.hero h1 {
  font-family:Lato;
  font-weight: 400;
  font-size:75px;
  letter-spacing: 2px;
  padding-right:15%;
}
.hero p {
  padding-top:50px;
  font-weight:200;
  font-size:20px;
  padding-right:30%;
}
.hero {
  font-family:Lato;
  padding:0 20px 0 100px;
  display:flex;
  background:transparent;
  background-position: center center;
  color:var(--tx-color);
  min-height:83vh;
  align-items: center;
  background-size: cover;
}
.heroLeft {
  padding:100px 20% 0 0;
}
.parallax {
  padding-top:0vh;   
}
.bg1 {
  background-position-y:35%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment:fixed;
  opacity:var(--opacity);
}
.bg2 { 
  opacity:var(--opacity);
  background-size: contain;
}
.resultsImg {
  height: auto;
  width: 90%;
  border-radius: 10px;
}
.flip {transform: translateY(180deg)}
  .polygon {
  fill:var(--stroke);
}
.chart {
  stroke:var(--stroke);
  stroke-dasharray: 700;
  stroke-dashoffset: 650;
  animation: draw 2.5s forwards;
}
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
.afterHero {
  padding-bottom:100px;
  overflow-x: hidden;
}
.imageFill {
  z-index:-5;
  margin-top:-200px;
  opacity:0.5;
}
.slider {
  align-items:center;
  display:flex;
  white-space: nowrap;
  display: inline-block;
  animation: slide 30s linear infinite;
  width:100%;
}
.card {padding:50px 20px; background:#333; border-radius: 10px;}
.card h4 {
  font-family:LatoLight;
  font-weight:300;
  font-size:30px;
}
.card p {
  font-family: Manrope;
  font-weight: 200;
  font-size:17px;
  line-height:25px;
}
.card:hover {
  background:#3f3f3f;
  transition:0.3s ease-in-out;
}
.roundmarker{
  padding:6px;
  border:2px solid #f00;
  border-radius: 50%;
}
.number {
  padding:5px 10px;
  background:#2a2a2a;
  border-radius:20px;
  font-family: Lato;
}
.workflowbtn {padding:0; line-height: 0; color:#222;}
.workflowbtn:hover {background:none; color:#f00;}
.slider > img {height:50px; width:auto; display: inline-block;}
@keyframes slide {
    0% {
          -webkit-transform: translateX(0%);
          -ms-transform: translateX(0%);
          transform: translateX(0%);
      }
      
      100% {
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
      }
}
  .gear { height:45px; width:45px }
  .counter {font-size:40px; text-align:center; align-items:center;}
  .counterIcon {font-size:60px;}
  .counterWrapper {background-color:var(--counter-bg);}
  .counter { 
    border-radius:50%; 
    border:1px solid #ffffff70;
    min-height:280px;
  }
  .counterNumber {
    font-family: Lato;
    font-weight:500;
    font-size:35px;
  }
#inpagemenu {color:#aaa;}
#inpagemenu:hover {border-bottom: 5px solid #dd0000;}
MdOutlineArrowDropDown {padding-top:20px;}

/* TESTIMONIALS */ /* TESTIMONIALS */ /* TESTIMONIALS */ /* TESTIMONIALS */ /* TESTIMONIALS */
.testimonialBox {
  border:3px solid #a3a3a3;
  border-radius: 20px;
  padding: 50px 20px;
  background:#222;
  margin-top: -63%;
  z-index: 10;
  position:relative;
}
.testimonialImage {
  height:200px;
  width: 200px;
}
.testimonialGiverName {
  font-family:Lato;
  font-size: 15px;
}
.testimonialGiverTitle {
  font-size:12px;
}
.testimonialTitle {
  font-size: 25px;
}
.testimonialText {
  font-size:17px;
  line-height: 25px;
}


@media screen and (max-width:1000px) {
  .hero {
    padding:0vh 20px;
    height:80vh;
    margin-top:20px;
    background-position: var(--position);
  }
  .heroVideo {
    width: auto;
    height: 100vh;
    margin-left:-100%!important;
  }
  .heroVideoSection {
    z-index: 1;
    position:absolute;
    background-color:#00000090;
    top:0;
    left:0;
    width:100%;
  }
  .heroTop {
    margin-top: 0;
    min-height: fit-content;
  }
  .hero h1 {
  font-family:Lato;
  font-weight: 500;
  font-size:50px;
  letter-spacing: 2px;
  padding-right:0;
  }
  .hero p {
    padding-top:10px;
    font-size:19px;
    font-weight:500;
    padding-right: 0;
  }
  .parallax {
    padding-top:15vh;   
  }
  #divider {
    height:70px;
    background-size:cover;
    background-repeat: no-repeat;
  }
  .bg1 {
    background-position-y:35%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment:;
    padding-top:0;
  }
  .bg2 { 
    background-size: contain;
  }
  .heroLeft {padding:0 40% 0 0;}
  .heroRight {display:none;}
  .gear { height:20px; width:20px }
  .counter {font-size:30px;} .counterIcon {font-size:45px;}
  .counters {padding:0 10px;}
  .afterHero {padding-bottom:50px;}

  /* TESTIMONIALS */ /* TESTIMONIALS */ /* TESTIMONIALS */ /* TESTIMONIALS */ /* TESTIMONIALS */
  .testimonialBox {
    padding: 30px 20px;
    margin-top: -81vh;
  }
  .testimonialImage {
    height:120px;
    width: 120px;
  }
  .testimonialGiverName {
    font-family:Lato;
    font-size: 20px;
  }
  .testimonialGiverTitle {
    font-size:15px;
  }
  .testimonialTitle {
    font-size: 21px;
  }
  .testimonialText {
    font-size:18px;
    line-height: 25px;
  }
}
@media screen and (max-width:650px) {
    .heroLeft {padding:0;}
}
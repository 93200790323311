* {
        margin: 0;
        padding: 0;
        border: 0;
    }
.loader {
  background-color: #9FDDFE;
  overflow-x:hidden;
  width:100vw;
  height:100vh;
  overflow:hidden;
  display:flex;
  justify-content:center;
  align-items:center;
}
.svg_logo_wrapper {
  text-align:center;
}
.animlogo {
  height:200px;
  width:530px;
}
.text {
  font-family:Rosario;
  font-size:150px;
  font-weight:900;
  letter-spacing:7px;
  stroke:#000;
}
.svg_logo_lines {
  stroke:#000;
  stroke-width:5;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
   50% {
    opacity: 0;
    transform: translateY(2000px);
  }
   80% {
    opacity: 0;
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
} 
/* media query for responsiveness */
@media screen and (max-width:950px) { 
.loader {
  padding-left:14vw;
}
.svg-logo-wrapper {
  padding-top: 20vh;
  overflow-x:hidden!important;
}
.text {
  font-weight:500;
  letter-spacing:7px;
}
  .animlogo {
  height:200px;
  width:500px;
  scale: 0.7;
  margin-left:-32vw;
  }
}

* {
        margin: 0;
        padding: 0;
        border: 0;
  }
@font-face {
  font-family: "Lato";
  src: url('./fonts/Lato/Lato-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "LatoBold";
  src: url('./fonts/Lato/Lato-Black.ttf') format("truetype");
}
@font-face {
  font-family: "LatoLight";
  src: url('./fonts/Lato/Lato-Light.ttf') format("truetype");
}
@font-face {
  font-family: "Manrope";
  src: url('./fonts/Manrope/Manrope-VariableFont_wght.ttf') format("truetype");
}
:root {
--bg-pg:#222222;
--tx-color:#fff;
--social-icons:#000;
--bg-swal:#f4f4ed;
--toggle:hsl(230, 17%, 14%);
}

body {
  overflow-x:hidden;
}
.sticky {position: sticky; top:0; position:relative;}
.appScreen {
  width:100%;
  background-color:var(--bg-pg);
  height: auto;
  padding: 0 0px;
  color:var(--tx-color);
  justify-content: center;
}
.appScreen h2, h3 {
  font-family:Lato;
  font-size:40px;
  font-weight:500;
}

.appScreen h1, h2, h3, h4, h5, h6 {font-family: Lato;}
.actual {max-width:1440px;}
section {padding:100px 100px;}
.nooverflowx {overflow-x: hidden;}
.button {
  font-family:Lato;
  font-size:20px;
  padding:10px 20px;
  background-color:#ff0000;
  border:2px solid #ff0000;
  color:#fff;
  border-radius: 5px;
}
.button:hover {
  color: #ff0000;
  text-align:center;
  background:transparent;
  border:2px solid #ff0000;
  transition:0.5s ease;
}
p {
  font-family:Manrope;
  font-size:17px;
  font-weight: 300;
  line-height:40px;
  letter-spacing:1.5px;
}
.bigHeading {
  font-size: 40px;
  padding:20px 0;
  font-family: Lato;
  font-weight:500;
}
.bigText {font-size: 25px;}
.mspacer {padding-top:0px;}
.scrollingTextBg {
  font-size:220px; 
  font-family:LatoBold; 
  font-weight:900;
  z-index:-100;
  margin-bottom: -150px;
}
.overscroll {
  font-family:Lato;
  font-weight:900;
  font-size:50px;
}
.pt0 {padding-top: 0;} 
/*Animations*/
.spin { 
  animation:fa-spin 3s ease infinite;
  animation-delay:0s;
  animation-timing-function: ease-in-out;  
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.slideIn {
	-webkit-animation: wpr-anim-text-slide-in 0.6s;
	animation: wpr-anim-text-slide-in 0.6s;
}
@keyframes wpr-anim-text-slide-in {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		transform: translateY(-100%);
	}
	60% {
		opacity: 1;
		-webkit-transform: translateY(20%);
		-ms-transform: translateY(20%);
		transform: translateY(20%);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
}

/*General column sections*/
a {text-decoration: none; color:#fff;}
a:hover, a:active {color:#f00}
.columns {
  display:flex;
  flex-direction:row;
  align-items:center;
}
.row {
  display:flex;
  flex-direction:column;
  align-items:center;
}
.sticky {position:sticky}
.grid{display:grid} .grid10 {grid-gap:10px;} .grid20 {grid-gap:20px;} .grid50 {grid-gap:50px;}.grid100 {grid-gap:100px;}
.gridBox{border-radius:10px; padding:20px;} .gridBox:hover{background-color:; box-shadow:2px solid #333}
.blackBg{background-color:#000;} .redBg{background-color:#f00; color:#f4f4ed;} .greyBg{background-color:#333;}
.columns50{width: 50%;} .columns3{width:33.3%;} .columns60{width:60%} .columns70{width:70%} .columns30{width:30%} .columns40{width:40%} 
.columns25{width:25%} .columns100{width:100%} .columns90{width:90%} 
.pad {padding-left:10%; padding-right: 10%;}
.fullHeight {height:90vh;}
.smHeading {
  font-family:Lato;
  font-size:25px;
  font-weight:500;
}
.boldHeader {
  font-family:Lato;
  font-size:52px;
  font-weight:600;
  text-align:center;
  padding: 0px 0  20px;
}
.border {
  border-top: 1px solid var(--bg-pg);
  border-bottom: 1px solid var(--bg-pg);
}
.borderTop {border-top: 1px solid #999}
.tag {background-color: #f00; padding:5px 15px 7px; border-radius: 5px; font-family: Lato;}
.uppercase {text-transform: uppercase;}
.down {margin-bottom: -3px;}

/*Backgrounds*/
.gradientBg {
  background-position: center;
  //background: radial-gradient(circle farthest-corner at 25% 50%, rgba(128,147,241,0.3) 0%, rgba(159,221,254,0.3) 10%, rgba(244,244,237,1) 60%);
}
.hide {display:none;} .dHide {display:none;} .dHideFl {display:none} .hidden {visibility: hidden;}
/*Spacers*/
.spacer50 {padding-top:50px} .spacer20 {padding-top:20px} .spacer30 {padding-top:30px} .spacer100 {padding-top:100px}

/*Paddings*/
.padLeft50 {padding-left:50px;}
.centralHero {padding:0 15%}
.pt0 {padding-top: 0;} .pb0 {padding-bottom: 0;}
.m0 {margin:0}
.p5 {padding:5px}

/*Font Weights*/
.wt100 {font-weight: 100;} .wt200 {font-weight: 200;} .wt300 {font-weight: 300;} .wt400 {font-weight: 400;}
.wt500 {font-weight: 500;} .wt600 {font-weight: 600;} .wt700 {font-weight: 700;} .wt800 {font-weight: 800;}
.wt900 {font-weight: 900;}

/*alignment*/
.center {text-align:center} .right {text-align:right} .left {text-align:left} .flex {display:flex;}
.alignright {justify-content: right;} .alignleft {justify-content: left;}
.flcenter {justify-content:center} .vcenter{align-items:center;} 
.start { align-items: flex-start;} .end {align-items: flex-end;}
.spaceBetween {justify-content:space-between;} .spaceAround {justify-content:space-around;}
.round {border-radius: 50%;}

/*colors*/
.red {color:#ff0000} .black {color:#000} .white{color:#fff} .grey {color: #ddd}

/*Footer*/
.footerH {font-family:Lato; font-size:20px;}
.footerP {font-size:15px; line-height:25px;}
.footerGridBox {padding:0 0 0 50px; align-items:flex-start; justify-content:flex-start;}
.footerGridBox a {text-decoration:none;}
.footerGridBox .smHeading  {font-family:Lato; font-weight: 500; font-size:18px; padding:25px 0 5px 0;}
.footerSmP {font-size:14px; line-height:20px; font-family:Manrope;}
.footerIcons {font-size:30px; color:#8093f1;}
.social {font-size:25px; color:var(--tx-color);} .footerGrid a {color:var(--tx-color); } .footerGrid a:hover {color:#f00; transition:0.2s ease;}
.divItem {padding:0 10px 10px 10px; border-radius:10px; color:var(--tx-color);}
/*.divItem a {color:var(--tx-color);}*/
.divItem:hover {background-color:#9fddfe33;}
.copyright{font-size:10px; padding:5px 0; color:#000; background-color:var(--Bite-blue); font-family:Manrope;}


@media screen and (max-width:1000px) {
  .appScreen {
    margin-left:0;
    width:100%;
    max-width:100%;
    overflow-y:scroll;
    display:block;
    justify-content:center;
    padding:50px 0 0 0;
  }
  .actual {max-width:99vw; overflow-y:scroll; height:auto; overflow-x: hidden;}
  section {padding:50px 20px;}
  .columns {flex-direction:column;}
  .slider {animation: slide 20s linear infinite;justify-content:flex-start;}
  .bigHeading {
    font-size:25px;
    padding:10px 10px;
    font-family: Lato;
    font-weight:500;
  }
  .bigText {font-size: 15px;}
  .appScreen h2, h3 {font-size:25px}
  .boldHeader {
    font-size:25px;
    font-weight:500;
    padding: 0;
    text-align:left;
  }
  .smHeading {
    font-size:20px;
    font-weight:500;
  }
  p {
    font-family:Manrope;
    font-size:18px;
    line-height:30px;
  }
  .scrollingTextBg {
    font-size:70px; 
    margin-bottom: -60px;
    height:120px;
    font-family:Lato; 
  }
  .overscroll {font-size: 25px;}
  .button {
    font-size:20px;
    padding:10px 20px;
  }
  .button:hover {
    padding:10px 20px;
    transition:1s ease-in-out;
  }
  .fullHeight {height:80vh;}
  .mfullHeight {height:92vh}
  .pad {padding:10px;}
  .reverse {flex-direction:column-reverse;}
  .mnopad {padding:0;} .dHide {display:block} .dHideFl {display:flex} .mHide {display:none;} .mcolumns{display:flex; flex-direction:row;grid-gap:40px; margin:0;}
  .mcenter {text-align:center; justify-content:center;} .mleft {text-align:left; justify-content:left;} .mspacer {padding-top:20px;}
  /*Columns*/
  .columns50, .columns3, .columns60, .columns70, .columns30, .columns40, 
  .columns25, .columns100{width:100%}
  .grid50, .grid100 {grid-gap:20px;} .grid20 {grid-gap:10px}

  /*Paddings*/
  .padLeft50 {padding-left:0px;}
  .centralHero {padding:0 5%}

  /*Spacers*/
  .spacer50 {padding-top:20px} .spacer20 {padding-top:20px} .spacer30 {padding-top:20px} .spacer100 {padding-top:20px}
  /*footers*/
  .footerGridBox {padding:0; align-items:top;}
  .footerP {font-size:15px; line-height:20px;}
  .footerSmP {font-size:14px; line-height:25px;}
  .footerGridBox .smHeading  {font-size:18px; padding:5px 0 5px 0;}
  .footerH {font-weight:600;}

}
@media screen and (min-width:1900px) {
.actual {
  margin:auto;
  
}
#divider {
  background-size: cover;
  background-repeat: no-repeat;
}
}
@media screen and (max-width:700px) {
  section {padding:30px 10px}
}